<template>
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="sketches"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="originals"
        transform="translate(-340.000000, -166.000000)"
        fill="#000000"
        fill-rule="nonzero"
      >
        <path
          id="Combined-Shape"
          d="M342,177 L342,180 L354,180 L354,177 L356,177 L356,180 C356,181.104569 355.104569,182 354,182 L342,182 C340.895431,182 340,181.104569 340,180 L340,177 L342,177 Z M349,166 L349,174.17 L351.59,171.59 L353,173 L348,178 L343,173 L344.41,171.59 L347,174.17 L347,166 L349,166 Z"
        ></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "DownloadIcon",
};
</script>
